const heroArrow = document.querySelector('.hero__arrow');
const hero = document.querySelector('.hero');

const scrollHeroDown = () => {
    window.scrollTo({
        top: hero.offsetHeight,
        left: 0,
        behavior: 'smooth'
    });
}

heroArrow.addEventListener('click', scrollHeroDown);
